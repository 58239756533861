<template>
  <v-container
    id="helpdesk"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="px-3"
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          id="search"
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('dataTable.SEARCH')"
          single-line
          hide-details
          clearable
          clear-icon="mdi-close"
        />
      </v-col>
      <v-col
        class="px-3"
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          id="searchAccountId"
          v-model="searchAccount"
          :items="accounts"
          name="account_id"
          item-text="accountname"
          item-value="_id"
          label="Société"
          clearable
          multiple
          prepend-icon="mdi-domain"
        />
      </v-col>
      <v-col
        class="px-3"
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          id="searchContactId"
          v-model="searchContact"
          :items="contacts"
          name="contact_id"
          item-value="_id"
          label="Collaborateur"
          clearable
          prepend-icon="mdi-account-tie"
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            {{ data.item.firstname }}
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            {{ data.item.firstname }} {{ data.item.lastname }}
          </template>
        </v-select>
      </v-col>
      <v-col
        class="px-3"
        cols="12"
        sm="6"
        md="2"
      >
        <v-select
          id="searchStatusId"
          v-model="searchStatus"
          :items="status"
          name="status"
          item-text="label"
          item-value="value"
          label="Statut"
          clearable
          prepend-icon="mdi-list-status"
          multiple
        />
      </v-col>
      <v-col
        class="mb-2 mt-2 pr-2"
        cols="12"
        sm="6"
        md="3"
      >
        <v-dialog
          v-model="dialog"
          max-width="80%"
          content-class="dlgNewEditItem"
        >
          <template v-slot:activator="{ on }">
            <div class="text-right">
              <v-btn
                color="secondary"
                class="btnNewItem"
                v-on="on"
              >
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                {{ $t('helpdesk.NEW_ELEMENT') }}
              </v-btn>
            </div>
          </template>
          <v-card :style="editedItem._id ? 'padding-top:0': ''">
            <v-card-title class="mb-4">
              <span class="text-h3">{{ formTitle }}</span>
              <span v-if="editedItem._id"> ({{ editedItem.ticketstatus }})</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <template v-if="editedItem._id">
                  <v-row style="text-align:right">
                    <v-col
                      cols="12"
                    >
                      N°<strong>{{ editedItem.ticket_no }}</strong>
                    </v-col>
                  </v-row>
                  <v-row style="background-color:#eee; margin-bottom:25px;">
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <div>
                        <strong>{{ $t('common.CREATED') }}</strong><br>
                        <span>{{ getFormat(editedItem.createdtime) }}</span>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <div>
                        <strong>Pour</strong><br>
                        <span v-if="editedItem['parent_id'].label">{{ editedItem['parent_id'].label }}</span>
                        <span
                          v-else
                          style="color:#cccccc"
                        >N.C.</span>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <div>
                        <strong>Par</strong><br>
                        <span v-if="editedItem['contact_id'].label">{{ editedItem['contact_id'].label }}</span>
                        <span
                          v-else
                          style="color:#cccccc"
                        >N.C.</span>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <v-row>
                  <v-col :cols="(typeof editedItem.attachments != 'undefined' && editedItem.attachments.length > 0) ? 9 : 12">
                    <template v-if="!editedItem._id/*(!editedItem._id || user.role !== 'Collaborateur' || editedItem.contact_id.value == user._id)*/">
                      <ValidationObserver
                        ref="observer"
                        v-slot="{ invalid }"
                        tag="form"
                        @submit.prevent="submit()"
                      >
                        <!-- DEBUT FORM CREA TICKET -->
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-select
                            id="parent_id"
                            v-model="editedItem.parent_id.value"
                            :error-messages="errors"
                            required
                            :items="accounts"
                            name="parent_id"
                            item-text="accountname"
                            item-value="_id"
                            label="Pour la société"
                            prepend-icon="mdi-domain"
                            @change="onAccountChange($event)"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('La demande concerne')"
                          rules="required"
                        >
                          <v-select
                            v-if="editedItem.parent_id.value.length > 0 && poles.length > 0"
                            id="cf_poles_id"
                            v-model="editedItem.cf_poles_id.value"
                            :error-messages="errors"
                            required
                            :items="poles"
                            name="cf_poles_id"
                            item-text="cf_1851"
                            item-value="_id"
                            label="La demande concerne"
                            prepend-icon="mdi-account-group"
                            @change="onPoleChange($event)"
                          />
                          <div
                            v-else-if="editedItem.parent_id.value.length > 0 && poles.length == 0"
                            style="color:red; text-align: center"
                          >
                            Aucun contrat actif n'a été trouvé pour cette société.
                          </div>
                        </ValidationProvider>
                        <v-select
                          v-if="parclogiciel.length > 0 && editedItem.cf_poles_id.value.length > 0"
                          id="cf_parclogiciel_id"
                          v-model="editedItem.cf_parclogiciel_id.value"
                          :items="parclogiciel"
                          name="cf_parclogiciel_id"
                          item-text="nom"
                          item-value="_id"
                          label="Pour le logiciel"
                          prepend-icon="mdi-laptop"
                        />
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('Objet de la demande')"
                          rules="required"
                        >
                          <v-select
                            v-if="subjects.length > 0 && editedItem.cf_poles_id.value.length > 0"
                            id="ticket_title"
                            v-model="editedItem.ticket_title"
                            :error-messages="errors"
                            required
                            :items="subjects"
                            name="ticket_title"
                            item-text="libelle"
                            item-value="libelle"
                            :label="$t('Objet de la demande')"
                            prepend-icon="mdi-help"
                            @change="onSubjectChange($event)"
                          />
                        </ValidationProvider>
                        <!--                        <v-text-field-->
                        <!--                          id="ticket_title"-->
                        <!--                          v-model="editedItem.ticket_title"-->
                        <!--                          requierd-->
                        <!--                          name="ticket_title"-->
                        <!--                          :label="$t('Objet')"-->
                        <!--                          :error="errors.length > 0"-->
                        <!--                          :error-messages="errors[0]"-->
                        <!--                          autocomplete="off"-->
                        <!--                          prepend-icon="mdi-help"-->
                        <!--                        />-->
                        <base-material-alert
                          v-if="disclaimer.length > 0"
                          style="background-color: transparent; color:red"
                        >
                          {{ disclaimer }}
                        </base-material-alert>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$t('Détail')"
                          rules="required"
                        >
                          <v-textarea
                            v-if="editedItem.ticket_title.length > 0 && editedItem.cf_poles_id.value.length > 0"
                            id="description"
                            v-model="editedItem.description"
                            required
                            name="description"
                            :label="$t('Détail de la demande')"
                            :error="errors.length > 0"
                            :error-messages="errors[0]"
                            autocomplete="off"
                            prepend-icon="mdi-comment"
                            rows="10"
                          />
                        </ValidationProvider>
                        <v-file-input
                          v-if="subjects.length > 0 && editedItem.ticket_title.length > 0 && editedItem.cf_poles_id.value.length > 0"
                          :label="$t('Pièce jointe 1')"
                          chips
                          truncate-length="15"
                          name="attachment1"
                          @change="attachFile1"
                        />
                        <v-file-input
                          v-if="subjects.length > 0 && editedItem.ticket_title.length > 0 && editedItem.cf_poles_id.value.length > 0"
                          :label="$t('Pièce jointe 2')"
                          chips
                          truncate-length="15"
                          name="attachment2"
                          @change="attachFile2"
                        />
                        <v-file-input
                          v-if="subjects.length > 0 && editedItem.ticket_title.length > 0 && editedItem.cf_poles_id.value.length > 0"
                          :label="$t('Pièce jointe 3')"
                          chips
                          truncate-length="15"
                          name="attachment3"
                          @change="attachFile3"
                        />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="red lighten3"
                            text
                            class="btnCancel"
                            @click="close"
                          >
                            {{ $t('dataTable.CLOSE') }}
                          </v-btn>
                          <v-btn
                            v-if="!editedItem._id && buttonSaveVisible"
                            color="green lighten3"
                            text
                            class="btnSave"
                            :disabled="invalid"
                            @click="save"
                          >
                            {{ $t('dataTable.SAVE') }}
                          </v-btn>
                          <div
                            v-if="!buttonSaveVisible"
                            class="green--text text--lighten3"
                          >
                            Envoi en cours
                          </div>
                        </v-card-actions>
                        <!-- FIN FORM CREA TICKET -->
                      </ValidationObserver>
                    </template>
                    <template v-else-if="editedItem._id">
                      <v-row>
                        <v-col cols="6">
                          <strong>Objet</strong><br>
                          <span v-if="editedItem['ticket_title']">{{ editedItem['ticket_title'] }}</span>
                          <span
                            v-else
                            style="color:#cccccc"
                          >N.C.</span>
                        </v-col>
                        <v-col cols="6">
                          <strong>Assigné à</strong><br>
                          <span v-if="editedItem['assigned_user_id'].label">{{ editedItem['assigned_user_id'].label }}</span>
                          <span
                            v-else
                            style="color:#cccccc"
                          >N.C.</span>
                        </v-col>
                      </v-row>
                      <v-row v-if="editedItem['cf_parclogiciel_id']">
                        <v-col cols="12">
                          <strong>Logiciel</strong><br>
                          <span v-if="editedItem['cf_parclogiciel_id'].label">{{ editedItem['cf_parclogiciel_id'].label }}</span>
                          <span
                            v-else
                            style="color:#cccccc"
                          >N.C.</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <strong>Problème évoqué</strong><br>
                          <span
                            v-if="editedItem['description']"
                            style="white-space: pre-wrap;"
                          >{{ editedItem['description'] }}</span>
                          <span
                            v-else
                            style="color:#cccccc"
                          >N.C.</span>
                        </v-col>
                      </v-row>
                      <br>
                    </template>
                  </v-col>
                  <v-col
                    v-if="typeof editedItem.attachments != 'undefined' && editedItem.attachments.length > 0"
                    cols="3"
                  >
                    <strong>Pièces jointes initiales</strong>
                    <div
                      v-for="attachment in editedItem.attachments"
                      :key="attachment._id"
                    >
                      <v-icon aria-hidden="false">
                        mdi-paperclip
                      </v-icon>
                      <a
                        :href="downloadAttachmentUrl({id: editedItem._id, attId: attachment._id, name: attachment.name})"
                      >{{ attachment.name }}</a>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="editedItem._id">
                  <v-col>
                    <base-material-card color="info">
                      <template v-slot:heading>
                        <div class="text-h3 font-weight-light">
                          <!--                            Détail des interventions-->
                          Nos échanges
                        </div>
                        <div class="text-subtitle-1 font-weight-light">
                          <!--                            New employees on 15th September, 2016-->
                        </div>
                      </template>
                      <v-row>
                        <v-col style="background-color:white;border-radius:10px;padding: 0px 10px 5px;margin:20px 15px 15px;box-shadow: 0 0 0.2em #ccc">
                          <template>
                            <ValidationObserver
                              ref="observer"
                              v-slot="{ invalid }"
                              tag="form"
                              @submit.prevent="submit()"
                            >
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="Name"
                                rules="required"
                              >
                                <v-textarea
                                  id="comment"
                                  v-model="comment.commentcontent"
                                  name="comment"
                                  required
                                  :label="$t('Envoyer un message au technicien')"
                                  :error="errors.length > 0"
                                  :error-messages="errors[0]"
                                  autocomplete="off"
                                  prepend-icon="mdi-comment"
                                  rows="3"
                                />
                              </ValidationProvider>
                              <v-file-input
                                v-if="commentAttachments"
                                v-model="comment.attachment1"
                                :label="$t('Pièce jointe 1')"
                                chips
                                truncate-length="15"
                                name="commentAttachment1"
                              />
                              <v-file-input
                                v-if="commentAttachments"
                                v-model="comment.attachment2"
                                :label="$t('Pièce jointe 2')"
                                chips
                                truncate-length="15"
                                name="commentAttachment2"
                              />
                              <v-file-input
                                v-if="commentAttachments"
                                v-model="comment.attachment3"
                                :label="$t('Pièce jointe 3')"
                                chips
                                truncate-length="15"
                                name="commentAttachment3"
                              />
                              <v-btn
                                v-if="buttonCommentVisible"
                                color="green lighten3"
                                text
                                class="btnSave"
                                :disabled="invalid"
                                style="float:right"
                                @click="putComment"
                              >
                                Envoyer
                              </v-btn>
                              <div
                                v-if="!buttonCommentVisible"
                                class="green--text text--lighten3"
                                style="float:right"
                              >
                                Envoi en cours
                              </div>
                              <v-btn
                                text
                                style="float:right"
                                @click="commentAttachments = !commentAttachments"
                              >
                                <v-icon aria-hidden="false">
                                  mdi-paperclip
                                </v-icon>
                              </v-btn>
                            </ValidationObserver>
                          </template>
                        </v-col>
                      </v-row>
                      <v-timeline
                        v-if="editedItem.pointages && editedItem.pointages.length >= 1"
                        align-top
                      >
                        <v-timeline-item
                          v-for="(pointage, i) in editedItem.pointages"
                          :key="i"
                          :color="pointage._module == 'ModComments' ? (pointage.customer.label ? 'info': 'pink darken-1'): 'success'"
                          :icon="pointage._module == 'ModComments' ? 'mdi-message-reply-text': 'mdi-tools'"
                          fill-dot
                          large
                          :right="pointage._module == 'ModComments' && pointage.customer.label ? true: false"
                          :left="pointage._module == 'ModComments' && pointage.customer.label ? false: true"
                        >
                          <v-card class="pa-6">
                            <v-chip
                              :color="pointage._module == 'ModComments' ? (pointage.customer.label ? 'info ': 'theme--dark pink darken-1'): 'success'"
                              class="text-overline mb-3"
                              small
                            >
                              <span
                                v-if="pointage.customer.label"
                                class="mdi mdi-account"
                              >&nbsp;{{ pointage.customer.label }}</span>
                              <span
                                v-else
                                class="mdi mdi-face-agent"
                              >&nbsp;{{ pointage.assigned_user_id.label }}</span>
                            </v-chip>

                            <p
                              class="text-subtitle-1 font-weight-light"
                              style="white-space: pre-wrap;"
                              v-text="pointage.description"
                            />
                            <p
                              class="text-uppercase text-body-2 text-right font-italic"
                              v-text="getFormat(pointage.CreatedTime, true)"
                            />
                            <br>
                            <div
                              v-for="attachment in pointage.attachments"
                              :key="attachment._id"
                            >
                              <v-icon aria-hidden="false">
                                mdi-paperclip
                              </v-icon>
                              <a
                                :href="downloadAttachmentUrl({id: pointage._id, attId: attachment._id, name: attachment.name})"
                              >{{ attachment.name }}</a>
                            </div>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                      <p
                        v-else
                        style="text-align:center"
                      >
                        <br>Votre demande est en cours de traitement.
                      </p>
                    </base-material-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-face-agent"
      :title="$t('helpdesk.TICKETS')"
      class="px-5 py-3"
    >
      <v-data-table
        :loading="dataTableLoading"
        :no-data-text="$t('dataTable.NO_DATA')"
        :no-results-text="$t('dataTable.NO_RESULTS')"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :items-per-page="10"
        :server-items-length="totalItems"
        class="elevation-1"
        :footer-props="{
          'items-per-page-text': $t('dataTable.ROWS_PER_PAGE'),
          'items-per-page-options': [5, 10, 25]
        }"
      >
        <template v-slot:[`item._id`]="{ item }">
          <v-row class="justify-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  id="edit"
                  icon
                  v-on="on"
                  @click="editItem(item)"
                >
                  <!--                  <v-icon v-if="user.role !== 'Collaborateur'">
                    mdi-pencil
                  </v-icon>
                  <v-icon v-else-if="item.contact_id.value == user._id">
                    mdi-pencil
                  </v-icon>-->
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('dataTable.VIEW') }}</span>
            </v-tooltip>
            <!--          <v-tooltip top>-->
            <!--            <template v-slot:activator="{ on }">-->
            <!--              <v-btn id="delete" icon v-on="on" @click="deleteItem(item)">-->
            <!--                <v-icon>mdi-delete</v-icon>-->
            <!--              </v-btn>-->
            <!--            </template>-->
            <!--            <span>{{ $t('dataTable.DELETE') }}</span>-->
            <!--          </v-tooltip>-->
          </v-row>
        </template>
        <template v-slot:[`item.createdtime`]="{ item }">
          {{ getFormat(item.createdtime) }}
        </template>
        <template v-slot:item.description="{ item }">
          <span v-if="item.description.length <= 60">{{ item.description }}</span>
          <span v-else>{{ item.description.substring(0, 60) }}(...)</span>
        </template>
        <template v-slot:item.cf_poles_id.label="{ item }">
          <span
            v-if="item.cf_poles_id.value == '40x28' || item.cf_poles_id.value == '40x29'"
            style="color:#922885"
          >
            {{ item.cf_poles_id.label }}
          </span>
          <span
            v-else-if="item.cf_poles_id.value == '40x18'"
            style="color:#ee7402"
          >
            {{ item.cf_poles_id.label }}
          </span>
          <span
            v-else-if="item.cf_poles_id.value == '40x7'"
            style="color:#b90748"
          >
            {{ item.cf_poles_id.label }}
          </span>
          <span v-else>{{ item.cf_poles_id.label }}</span>
        </template>
        <template v-slot:[`item.modifiedtime`]="{ item }">
          {{ getFormat(item.modifiedtime) }}
        </template>
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }}
          {{ $t('dataTable.OF') }}
          {{ itemsLength }}
        </template>
        <template v-slot:no-data>
          {{ $t('dataTable.NO_DATA') }}
        </template>
        <template v-slot:no-results>
          {{ $t('dataTable.NO_RESULTS') }}
        </template>
      </v-data-table>
      <ErrorMessage />
      <SuccessMessage />
    </base-material-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { getFormat, buildPayloadPagination } from '@/utils/utils.js'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('helpdesk.TICKETS')} - %s`,
      }
    },
    data () {
      return {
        buttonSaveVisible: true,
        buttonCommentVisible: true,
        dataTableLoading: true,
        delayTimer: null,
        dialog: false,
        search: '',
        pagination: {},
        editedItem: {},
        defaultItem: {
          ticket_title: '',
          parent_id: {
            label: '',
            value: '',
          },
          contact_id: {
            label: '',
            value: '',
          },
          cf_poles_id: {
            label: '',
            value: '',
          },
          cf_parclogiciel_id: {
            label: '',
            value: '',
          },
          attachment1: null,
          attachment2: null,
          attachment3: null,
          attachments: [],
          pointages: [],
          commentcontent: '',
        },
        fieldsToSearch: [],
        accounts: [],
        contacts: [],
        status: [],
        poles: [],
        parclogiciel: [],
        subjects: [],
        disclaimer: '',
        headers: [],
        headerSet: false,
        searchContact: '',
        searchAccount: '',
        searchStatus: ['Open', 'In Progress'],
        // Commentaire
        comment: {
          commentcontent: '',
          related_to: '',
          attachment1: null,
          attachment2: null,
          attachment3: null,
        },
        commentAttachments: false,
      }
    },
    computed: {
      ...mapGetters(['user']),
      formTitle () {
        return this.editedItem._id
          ? this.editedItem.ticket_title
          : this.$t('helpdesk.NEW_ELEMENT')
      },
      schemaFields () {
        for (const k in this.schema.fields) {
          if (this.schema.fields[k].name === 'modifiedtime') {
            delete this.schema.fields[k]
          }
        }
        return this.schema.fields
      },
      items () {
        return this.$store.state.records.records
      },
      totalItems () {
        return this.$store.state.records.totalRecords
      },
    },
    watch: {
      dialog (value) {
        return value ? true : this.close()
      },
      pagination: {
        async handler () {
          try {
            this.dataTableLoading = true
            await this.getRecords(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false

            if (!this.headerSet) {
              await this.getSchema()
              this.schema = this.$store.state.schema.schema
              this.fieldsToSearch.push(this.schema.labelFields)
              this.setHeaders()
            }

            // Initialisation des valeurs par défaut
            this.defaultItem.parent_id.value = this.user._account_id
            this.editedItem = Object.assign({}, this.defaultItem)

            // Chargement des comptes
            await this.getAccounts()
            this.accounts = this.$store.state.dependencies.accounts

            // Chargement des contacts
            await this.getContacts()
            this.contacts = this.$store.state.dependencies.contacts

            // On récupère les status de ticket
            for (const index in this.schema.fields) {
              if (this.schema.fields[index].name === 'ticketstatus') {
                this.status = this.schema.fields[index].type.picklistValues
              }
            }

            // Déclenchement de onAccountChange pour le compte par défaut
            this.onAccountChange(this.editedItem.parent_id.value)

            // Test si il faut ouvrir un ticket
            if (typeof this.$route.query._id !== 'undefined' && this.$route.query._id.length > 0) {
              this.items.forEach((item, i) => {
                if (item._id === this.$route.query._id) {
                  this.editItem(item)
                }
              })
            }
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true,
      },
      async search () {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          this.doSearch()
        }, 400)
      },
      async searchAccount () {
        this.doSearch()
      },
      async searchContact () {
        this.doSearch()
      },
      async searchStatus () {
        this.doSearch()
      },
    },
    methods: {
      ...mapActions(['getRecords', 'editRecord', 'saveRecord', 'deleteRecord', 'saveComment', 'getSchema', 'getAttachments', 'getAccounts', 'getContacts', 'getPoles', 'getPointages', 'getParcLogiciel', 'getConfiguration']),
      getFormat (date, hour = true) {
        window.__localeId__ = this.$store.getters.locale
        if (typeof date !== 'undefined' && date.length > 0 && !/^0000-00-00/.test(date)) {
          if (hour) {
            return getFormat(date, 'dd/MM/yyyy HH:mm')
          } else {
            return getFormat(date, 'dd/MM/yyyy')
          }
        } else {
          return ''
        }
      },
      setHeaders () {
        this.headers = [
          {
            text: this.$i18n.t('dataTable.ACTIONS'),
            value: '_id',
            sortable: false,
            width: 100,
          },
        ]
        for (const element of Object.entries(this.schema.list)) {
          const field = element[1]
          let valueField = field.name
          if (field.type.name === 'owner') {
            valueField = field.name + '.label'
          } else if (field.type.name === 'picklist') {
            valueField = field.name // + 'Translated'
          } else if (field.type.name === 'reference') {
            valueField = field.name + '.label'
          }
          this.headers.push({
            text: this.$i18n.t(field.label),
            value: valueField,
            sortable: true,
          })
        }
        this.headerSet = true
      },
      async onAccountChange (account) {
        this.editedItem.cf_poles_id.value = ''
        await this.getPoles({
          accountid: account,
        })
        this.poles = this.$store.state.dependencies.poles
      },
      async onPoleChange (pole) {
        await this.getParcLogiciel({
          accountid: this.editedItem.parent_id.value,
          polesid: pole,
        })
        this.parclogiciel = this.$store.state.dependencies.parclogiciel

        await this.getConfiguration({
          type: 'Objet Ticket',
          polesid: this.editedItem.cf_poles_id.value,
        })
        // console.log(this.$store.state.dependencies.configuration)
        this.subjects = this.$store.state.dependencies.configuration
        if (!this.subjects.find(o => o.libelle === this.editedItem.ticket_title) && this.editedItem.ticket_title.length > 0) {
          this.subjects.push({
            libelle: this.editedItem.ticket_title,
            description: '',
            cf_1855: '',
            cf_1849: '',
          })
        }
      },
      onSubjectChange (subject) {
        this.editedItem.description = ''
        const oSubject = this.subjects.find(o => o.libelle === subject)
        if (oSubject.description.length > 0) {
          this.editedItem.description = oSubject.description
        }
        if (oSubject.cf_1855.length > 0) {
          this.disclaimer = oSubject.cf_1855
        }
        if (oSubject.cf_1849.length > 0 && !oSubject.cf_1849.match(/Collaborateur/i)) {
          this.editedItem.cf_2011 = 'Oui'
        } else {
          this.editedItem.cf_2011 = 'Non'
        }
      },
      async doSearch () {
        try {
          this.dataTableLoading = true
          await this.getRecords(
            buildPayloadPagination(this.pagination, this.buildSearch()),
          )
          this.dataTableLoading = false
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch () {
        return this.search || this.searchAccount || this.searchContact || this.searchStatus
          ? { query: this.search, fields: this.fieldsToSearch.join(','), filters: { parent_id: this.searchAccount, contact_id: this.searchContact, ticketstatus: this.searchStatus } }
          : {}
      },
      async editItem (item) {
        if (this.editedItem.parent_id.value !== item.parent_id.value) {
          await this.onAccountChange(item.parent_id.value)
        }
        this.editedItem = Object.assign({}, item)
        if (this.editedItem.cf_poles_id.value.length > 0) {
          await this.onPoleChange(item.cf_poles_id.value)
          if (!this.poles.find(o => o._id === this.editedItem.cf_poles_id.value)) {
            this.poles.push({
              _id: this.editedItem.cf_poles_id.value,
              cf_1851: this.editedItem.cf_poles_id.label,
            })
          }
        }
        await this.getAttachments(this.editedItem._id)
        this.editedItem.attachments = this.$store.state.records.attachments
        await this.getPointages({ ticketid: this.editedItem._id })
        this.editedItem.pointages = this.$store.state.dependencies.pointages
        console.log(this.editedItem.pointages)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const response = await this.$confirm(
            this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
            {
              title: this.$t('common.WARNING'),
              buttonTrueText: this.$t('common.DELETE'),
              buttonFalseText: this.$t('common.CANCEL'),
              buttonTrueColor: 'red lighten3',
              buttonFalseColor: 'green',
            },
          )
          if (response) {
            this.dataTableLoading = true
            await this.deleteRecord(item._id)
            await this.getRecords(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          }
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          if (this.editedItem.parent_id.value !== this.defaultItem.parent_id.value) {
            this.onAccountChange(this.defaultItem.parent_id.value)
          }
          this.editedItem = Object.assign({}, this.defaultItem)
          this.disclaimer = ''
        }, 300)
      },
      async save () {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          try {
            this.buttonSaveVisible = false
            this.dataTableLoading = true
            // Updating item
            if (this.editedItem._id) {
              await this.editRecord(this.editedItem)
              await this.getRecords(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            } else {
              // Creating new item
              // On force le type = "Assistance"
              this.editedItem.cf_1291 = 'Assistance'
              this.editedItem.ticketpriorities = '3 - Normale'
              // this.editedItem.cf_1291 = 'TESTPORTAIL'
              await this.saveRecord(this.editedItem)

              // On crée un commentaire
              this.comment.commentcontent = this.editedItem.description
              this.comment.attachment1 = this.editedItem.attachment1
              this.comment.attachment2 = this.editedItem.attachment2
              this.comment.attachment3 = this.editedItem.attachment3
              await this.putComment(false)
              this.editedItem._id = null

              await this.getRecords(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            }
            this.buttonSaveVisible = true
            this.close()
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
            this.close()
          }
        }
      },
      async putComment (reload = true) {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.buttonCommentVisible = false
          try {
            this.comment.related_to = this.editedItem._id
            await this.saveComment(this.comment)
            if (reload) {
              await this.getPointages({ ticketid: this.editedItem._id })
              this.editedItem.pointages = this.$store.state.dependencies.pointages
              this.$forceUpdate()
            }
            this.comment.commentcontent = ''
            this.comment.attachment1 = null
            this.comment.attachment2 = null
            this.comment.attachment3 = null
            this.$refs.observer.reset()
            this.commentAttachments = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            // console.log('Fermeture popup en cas d erreur')
            // this.close()
          }
          this.buttonCommentVisible = true
        }
      },
      attachFile1 (file) {
        this.editedItem.attachment1 = file
      },
      attachFile2 (file) {
        this.editedItem.attachment2 = file
      },
      attachFile3 (file) {
        this.editedItem.attachment3 = file
      },
      downloadAttachmentUrl (obj) {
        const token = JSON.parse(window.localStorage.getItem('token'))
        return process.env.VUE_APP_API_URL + '/Get/Attachment' + `/${obj.id}` + '?attachment=' + obj.attId + '&token=' + token
      },
    },
  }
</script>

<style>
table.v-table {
  max-width: none;
}
</style>
